<template>
  <div class="comefare" :class="{ mobile: $vuetify.breakpoint.smAndDown }">
    <!-- page title -->
    <page-title></page-title>

    <!-- title -->
    <v-container class="body">
      <div class="pt-8">
        <h2 class="subtitle mb-4">{{ titolo }}</h2>
      </div>

      <!-- claim -->
      <div v-html="claim" class="comefare-description"></div>

      <!-- paragrafi -->
      <v-row
        v-for="paragrafo in paragrafi"
        :key="paragrafo.id"
        class="comefare-description pt-8"
      >
        <v-col cols="12">
          <p v-html="paragrafo.contenuto"></p>
        </v-col>
        <v-col v-if="paragrafo.tipo == 'TestoConMedia'" cols="12">
          <div class="container-img-testo">
            <img
              :src="baseUrl + paragrafo.url_relativa_img"
              class="img-testo"
            />
          </div>
        </v-col>
        <v-col v-if="paragrafo.tipo == 'TestoConWidget'" cols="12">
          <p v-html="paragrafo.widget"></p>
        </v-col>
      </v-row>

      <!-- allegati -->
      <h2 class="mt-16 section-title mb-8" v-if="allegati.length > 0">
        Allegati
      </h2>
      <v-row class="allegati">
        <v-col
          v-for="allegato in allegati"
          :key="allegato.nome"
          :class="allegato.tipo == 'Image' ? 'col-6 col-sm-3 col-item-allegati' : 'col-12 col-item-allegati'"
        >
          <!-- document -->
          <div v-if="allegato.tipo == 'Document' || allegato.tipo == 'Other'">
            <div
              class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default mb-4"
            >
              <a :href="baseUrl + allegato.url"
                ><v-icon>file_copy</v-icon>{{ decode(allegato.nome) }}</a
              >
            </div>
          </div>
          <!-- image -->
          <div
            v-if="allegato.tipo == 'Image'"
            class="allegato container-img-testo-icon"
          >
            <img
              :src="baseUrl + allegato.url"
              @click="downloadAllegato(baseUrl + allegato.url, allegato.nome)"
              class="allegato-img text-center img-testo align-center my-4"
              alt=" "
            /><v-icon class="download-icon">get_app</v-icon>
            >
          </div>
          <!-- video -->
          <div v-if="allegato.tipo == 'Video'">
            <video class="video" controls :src="baseUrl + allegato.url"></video>
            <div class="text-center">
              <v-btn
                @click="downloadAllegato(baseUrl + allegato.url, allegato.nome)"
              >
                {{ allegato.nome }}<v-icon>get_app</v-icon>
              </v-btn>
            </div>
          </div>
          <!-- remote video -->
          <div v-if="allegato.tipo == 'Remote Video'">
            <iframe
              class="video"
              :src="baseUrl + allegato.url"
              frameborder="0"
            ></iframe>
          </div>
          <!-- audio -->
          <div v-if="allegato.tipo == 'Audio'">
            <audio :src="baseUrl + allegato.url" controls></audio>
            <div>
              <v-btn
                @click="downloadAllegato(baseUrl + allegato.url, allegato.nome)"
              >
                {{ allegato.nome }}<v-icon>get_app</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- links -->
      <h2 class="mt-8 section-title mb-8" v-if="links.length > 0">Link</h2>
      <v-row>
        <v-col
          class="col-12 col-sm-auto link-list"
          v-for="link in links"
          :key="link"
        >
          <v-btn><div v-html="link"></div></v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PageTitle from "../components/PageTitle"
import { RICERCARE, RIUSARE, ADERIRE, YUCCA } from "../router/routes"
import {
  getComeRicercare,
  getComeRiusare,
  getComeAderire,
  getUtilizzareYucca,
} from "../services/api"
export default {
  name: "ComeFarePer",
  components: {
    PageTitle,
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      item: [],
      itemType: "",
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    titolo () {
      return this.item?.titolo ?? ""
    },
    paragrafi () {
      return this.item?.paragrafi ?? []
    },
    allegati () {
      return this.item?.allegati ?? []
    },
    links () {
      let links = this.item?.link?.split(",") ?? []
      return links != "" ? links : []
    },
    claim () {
      return this.item?.claim ?? ""
    },
  },
  methods: {
    decode (value) {
      return unescape(value)
    },
    async loadData () {
      this.$emit("setLoading", true)

      let comeFarePromise
      switch (this.$route.name) {
        case RICERCARE.name:
          if (this.itemType == "ricercare") return
          this.itemType = "ricercare"
          comeFarePromise = getComeRicercare()

          break

        case RIUSARE.name:
          if (this.itemType == "riusare") return
          this.itemType = "riusare"
          comeFarePromise = getComeRiusare()

          break

        case ADERIRE.name:
          if (this.itemType == "aderire") return
          this.itemType = "aderire"
          comeFarePromise = getComeAderire()

          break

        case YUCCA.name:
          if (this.itemType == "yucca") return
          this.itemType = "yucca"
          comeFarePromise = getUtilizzareYucca()

          break

        default:
          return
      }

      try {
        let { data } = await comeFarePromise
        this.item = data[0]
      } catch (err) {
        console.log(err)
      }

      this.$emit("setLoading", false)
    },
    downloadAllegato (url, name) {
      var element = document.createElement("a")
      element.setAttribute("href", url)
      element.setAttribute("download", name)
      element.style.display = "none"
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    },
  },
  watch: {
    $route () {
      this.loadData()
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";
.comefare {
  a {
    color: #2ab5b9;
  }
}
.subtitle {
  text-align: justify;
  font: normal normal 600 40px/40px Open Sans;
  letter-spacing: 0px;
  color: #000000;
}
.comefare-description {
  text-align: justify;
  font: normal normal normal 20px/30px Open Sans;
  letter-spacing: 0px;
  color: $text-dark;
}
.section-title {
  font: normal normal bold 27px/37px Open Sans;
  letter-spacing: 0px;
}
.allegati {

  .col-item-allegati{

    padding-top:0!important;
    padding-bottom:0!important;
  }
  .allegato-img {
    cursor: pointer;
    .download-icon {
      color: $text-white;
    }
  }
  a {
    text-decoration: none;
    color: $text-dark;
  }
  .allegato {
    &:hover {
      transform: scale(1.2);
    }
  }
  .video {
    max-width: 500px;
  }
}
.video {
  max-width: 500px;
}
.link-list {
  a {
    text-decoration: none;
    color: $text-dark;
  }
}
.mobile {
  .subtitle {
    text-align: inherit;
  }
  .comefare-description {
    text-align: inherit;
  }
}
</style>